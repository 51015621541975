import React, { useEffect, useState } from "react"
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "gatsby-source-storyblok"

import Layout from "../components/structural/layout"
import Background from "../components/global/background/background"
import Seo from "../components/seo"
import Video from "../components/global/video"
import { graphql } from "gatsby"

const IndexPage = props => {
  const { data } = props
  let story = data.index
  story = useStoryblokState(story)

  const { content } = story

  const { desktop_image, mobile_image, desktop_video, mobile_video } = content

  const [domLoaded, setDomLoaded] = useState(false)
  const [isVideoReady, setIsVideoReady] = useState(false)

  const videoPlayHandler = () => {
    setIsVideoReady(true)
  }

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  const components = content?.content?.map(blok => (
    <StoryblokComponent blok={blok} doc={blok} key={blok._uid} />
  ))

  return (
    <Layout>
      <div {...storyblokEditable(content)} className={`mx-auto bg-se-black`}>
        <section
          style={domLoaded ? undefined : { visibility: "hidden" }}
          className={`m-0 p-0 bg-se-black}`}
        >
          {/* {domLoaded && ( */}
          <>
            <Background
              className={`brand-background-hero-responsive block md:hidden overflow-hidden bg-cover bg-fixed bg-center bg-no-repeat text-center w-full aspect-[9/16] md:aspect-video`}
              src={
                mobile_image && mobile_image.filename
                  ? mobile_image?.filename
                  : desktop_image?.filename
              }
              sm="900x1600"
              md="900x1600"
              lg="900x1600"
            >
              {mobile_video && (
                <Video
                  containerClassName={`block md:hidden aspect-[9/16] ${
                    isVideoReady ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-500 absolute top-0 left-0 right-0 bottom-0 z-40`}
                  onVideoPlay={videoPlayHandler}
                  autoplay={true}
                  background={true}
                  light={false}
                  loop={true}
                  muted={true}
                  video={mobile_video}
                />
              )}
              <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden flex h-full items-center justify-center p-6">
                <h1 className="text-se-white text-center border-[12px] border-se-white text-[70px] leading-[56px] font-black p-[10px] font-sans w-fit se-background-opaque">
                  We Are Super Evil
                </h1>
                <div className="absolute w-100 text-center bottom-0">
                  <span
                    className="text-se-white animate-bounce material-icons"
                    style={{ fontSize: "3rem" }}
                  >
                    keyboard_double_arrow_down
                  </span>
                </div>
              </div>
            </Background>

            <Background
              className={`max-h-screen mx-auto hidden md:block brand-background-hero-responsive relative overflow-hidden bg-cover bg-fixed bg-center bg-no-repeat text-center aspect-[9/16] md:aspect-[21/9]`}
              src={desktop_image?.filename}
              sm="900x1600"
              md="2100x900"
              lg="2100x900"
            >
              {desktop_video && (
                <Video
                  containerClassName={`hidden md:block ${
                    isVideoReady ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-500 absolute top-0 left-0 right-0 bottom-0 z-40`}
                  onVideoPlay={videoPlayHandler}
                  autoplay={true}
                  background={true}
                  light={false}
                  loop={true}
                  muted={true}
                  video={desktop_video}
                />
              )}
              <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden flex flex-col h-full items-center justify-center p-12">
                <h1 className="font-sans w-fit se-background-opaque text-se-white text-center font-black border-se-white border-[12px] lg:border-[24px] text-[70px] lg:text-[120px] leading-[56px] lg:leading-[96px] p-[10px] lg:p-[15px]">
                  We Are Super Evil
                </h1>
                <div className="absolute w-100 text-center bottom-0">
                  <span
                    className="text-se-white animate-bounce material-icons"
                    style={{ fontSize: "4rem" }}
                  >
                    keyboard_double_arrow_down
                  </span>
                </div>
              </div>
            </Background>
          </>
        </section>
        {components}
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = ({ data }) => {
  const story = useStoryblokState(data.index)

  const { seo, seo_author, seo_image, seo_keywords } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
      keywords={seo_keywords}
    />
  )
}

export const QUERY = graphql`
  query IndexDefaultQuery {
    index: storyblokEntry(full_slug: { eq: "home" }, lang: { eq: "default" }) {
      content
      name
      full_slug
      uuid
      lang
      id
      internalId
    }

    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
